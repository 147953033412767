import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DatetimeChangeEventDetail } from '@ionic/angular';
@Component({
  selector: 'app-custom-date-range',
  templateUrl: './custom-date-range.component.html',
  styleUrls: ['./custom-date-range.component.scss'],
  providers: [DatePipe]
})
export class CustomDateRangeComponent  implements OnInit {
  @Input() id!: string;
  @Input() min!: string;
  @Input() start!: string;
  @Input() end!: string;

  @Output() updateStart = new EventEmitter<string>();
  @Output() updateEnd = new EventEmitter<string>();

  currentUserSelection: string[] | undefined;
  previousUserSelection: string[] | undefined;

  constructor(private datePipe: DatePipe) { }

  ngOnInit() {
    if(this.start && this.end) {
      this.currentUserSelection = this.previousUserSelection = [this.start, this.end];
    } else {
      this.currentUserSelection = []
    }
  }

  defineHighlightStyle = (date: string) => {
    if (!this.currentUserSelection || this.currentUserSelection.length < 2) return;

    const startDateCurrentRange = this.datePipe.transform(this.currentUserSelection[0], 'yyyy-MM-dd');
    const endDateCurrentRange = this.datePipe.transform(this.currentUserSelection.at(-1) as string, 'yyyy-MM-dd');
    const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');

    // const startDateCurrentRange = DateTime.fromFormat(this.currentUserSelection[0], 'yyyy-MM-dd');
    // const endDateCurrentRange = DateTime.fromFormat(this.currentUserSelection.at(-1) as string, 'yyyy-MM-dd');
    // const formattedDate = DateTime.fromFormat(date, 'yyyy-MM-dd');
    const isDateWithinRange = formattedDate > startDateCurrentRange && formattedDate < endDateCurrentRange;

    if (isDateWithinRange) {
      return {
        backgroundColor: '#4818f717',
      };
    }
  };

  handleDateChange(event: CustomEvent<DatetimeChangeEventDetail>) {
    const value = event.detail.value as string[] | undefined;
    this.currentUserSelection = value;

    if (!this.currentUserSelection || this.currentUserSelection.length < 2) return;

    this.currentUserSelection.sort((a, b) => (a > b ? 1 : -1));

    if (this.previousUserSelection && this.previousUserSelection.length >= 2) {
      const isSelectionWithinPreviousRange = this.currentUserSelection.length < this.previousUserSelection.length;

      if (isSelectionWithinPreviousRange) {
        this.currentUserSelection = this.previousUserSelection.filter(
          (date) => !this.currentUserSelection!.includes(date)
        );
      } else {
        this.currentUserSelection = [this.currentUserSelection.at(-1)!];
      }
    }

    this.previousUserSelection = this.currentUserSelection;
    this.updateStart.emit(this.currentUserSelection[0]);
    this.updateEnd.emit(this.currentUserSelection.at(-1)!);
  }


}
