<!-- <ion-datetime
class="clink-date-time-range"
presentation="date"
locale="es-ES"
[multiple]="true"
[value]="currentUserSelection"
(ionChange)="handleDateChange($event)"
[highlightedDates]="defineHighlightStyle"
[attr.id]="id"
[attr.min]="min"
></ion-datetime> -->
<ion-datetime
class="clink-date-time-range"
presentation="date"
locale="es-ES"
[multiple]="true"

[value]="currentUserSelection"
(ionChange)="handleDateChange($event)"
[highlightedDates]="defineHighlightStyle"
[attr.id]="id"
></ion-datetime>
